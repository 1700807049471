<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        :class="
          !$vuetify.breakpoint.mobile ? 'toolbarHead' : 'toolbarHeadMobile'
        "
        color="greyBase"
        dark
        flat
        dense
        height="30px"
      >
        <v-btn icon @click="$router.go(-1)">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="!$vuetify.breakpoint.mobile"
          style="font-size: 16px"
        >
          <b class="mr-3">{{ item.name }}</b>
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs height="30px" v-model="tab" class="tabby">
        <v-tab
          v-for="tab in tabs"
          :key="tab.value"
          :value="tab.value"
          :href="`#${tab.value}`"
          style="font-size: 12px"
        >
          <v-icon small color="grey" class="mr-2">{{ tab.icon }}</v-icon>
          <span>{{ tab.name }}</span>
          <v-chip class="ml-2" small v-if="tab.value == 'bookingRequests'">{{
            bookingRequestCount.count
          }}</v-chip>
        </v-tab>
      </v-tabs>
      <div class="contents">
        <div class="mainContentWrap">
          <div class="mainContent">
            <v-tabs-items v-model="tab" style="height: inherit; width: 100%">
              <v-tab-item  style="height: inherit; width: 100%"  value="info">
                <Info :item="item" />
              </v-tab-item>
              <v-tab-item  style="height: inherit; width: 100%"  value="users">
                <User />
              </v-tab-item>
              <v-tab-item  style="height: inherit; width: 100%"  value="carrier-contract">
                <CarrierContract />
              </v-tab-item>
              <v-tab-item  style="height: inherit; width: 100%"  value="integration">
                <Integration :organisation="item" />
              </v-tab-item>
              <v-tab-item  style="height: inherit; width: 100%"  value="service-failure">
                <ServiceFailureCategory />
              </v-tab-item>
              <v-tab-item  style="height: inherit; width: 100%"  value="contracts">
                <Contract :organisation="item" />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
  
  <script>
import CarrierContract from "../components/Management/CarrierContract.vue";
import Info from "../components/Management/Info.vue";
import Integration from "../components/Management/Integration.vue";
// import Overview from '../components/Management/Overview.vue'
// import Role from '../components/Management/Roles.vue'
import ServiceFailureCategory from "../components/Management/ServiceFailureCategory.vue";
// import Team from '../components/Management/Team.vue'
import User from "../components/Management/Users.vue";
import Contract from "../components/Management/Contract.vue";
//New Components for rendering the vue Markdown Component
// import Documents from '../components/Management/Documents.vue'
export default {
  components: {
    CarrierContract,
    Info,
    Integration,
    // Overview,
    // Role,
    ServiceFailureCategory,
    // Team,
    User,
    Contract,
    // Documents,
  },
  data: () => ({
    item: {},
    loaded: false,
    tabs: [
      { name: "Info", value: "info" },
      { name: "User Management", value: "users" },
      { name: "Integrations", value: "integration" },
      { name: "Service Failures", value: "service-failure" },
    ],
    tab: 0,
  }),
  async created() {
    this.loaded = false;
    this.item = await this.$API.getOrganisation();
    this.loaded = true;
  },
};
</script>
  
  <style lang="scss">
.active-tab {
  background-color: var(--v-background-base) !important;
  color: var(--v-primaryText-base) !important;
  font-size: 10px;
  border-top: 1px solid var(--v-primary-base);
  //  border-right: 1px solid lightgrey;
  //  border-left: 1px solid lightgrey;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyDarker-base) !important;
  border-radius: 0px;
}
</style>